<template lang="pug">
.step-field-mapping
  om-toast.mb-4(:closable="false" type="icon" color="warning")
    div {{ $t('integrationWarnings.only_phone') }}

  .title {{ $t('integrationFlow.integrationModal.general.fieldMappingAltTitle', { type: 'Recart' }) }}
  .d-flex.fields-wrapper.w-100
    om-toast.mb-6(v-if="!validByAlert.fields" color="danger" :closable="false" type="side") {{ $t('integrationFlow.integrationModal.general.checkFields', { integration: 'Recart' }) }}
    .d-flex.fields-title
      .om-fields-header {{ $t('integrationFlow.integrationModal.general.omInputFields') }}
      .arrow-block
      .integration-fields-header {{ $t('integrationFlow.integrationModal.general.integrationFields', { type: 'Recart' }) }}
    .d-flex.fields(v-for="(binding, index) in formBindings")
      binding-row.w-100.justify-content-between(
        :isNewFlow="true"
        :binding="binding"
        :index="index"
        :fields="fields"
        :type="settings.type"
        :hasError="bindingRowHasError(binding)"
      )
  .binding-error-message(v-if="missingRequiredFields.length") {{ bindingErrorMessage }}
</template>

<script>
  import { UilArrowRight } from '@iconscout/vue-unicons';
  import bindingMixin from '@/mixins/integration/binding';
  import BindingRow from '@/components/IntegrationBindings/Row';

  export default {
    components: {
      UilArrowRight,
      BindingRow,
    },

    mixins: [bindingMixin],

    props: {
      validByAlert: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        fields: [
          {
            name: 'phone',
            id: 'phoneNumber',
          },
        ],
        defaultCampaignDataFields: [],
      };
    },

    mounted() {
      this.$on('isBindingModified', (value) => this.$emit('isModified', value));
    },
  };
</script>
